<template>
  <textarea></textarea>
</template>

<script>
import { Jodit } from './jodit/jodit.min.js'
import { getToken } from '@/utils/auth'
import {cleanLogininfor} from "../../api/monitor/logininfor";
export default {
  name: 'JoditEditor',
  props: {
    value: { type: String, required: true },
    buttons: { type: Array, default: null },
    extraButtons: { type: Array, default: null },
    config: { type: Object, default: () => ({}) },
    plugins: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false }
  },
  data: () => ({ editor: null, height: 0 }),
  computed: {
    editorConfig () {
      const config = { ...this.config }
      if (this.buttons) {
        config.buttons = this.buttons
        config.buttonsMD = this.buttons
        config.buttonsSM = this.buttons
        config.buttonsXS = this.buttons
      }
      if (this.extraButtons) {
        config.extraButtons = this.extraButtons
      }
      return config
    }
  },
  watch: {
    value (newValue) {
      if (this.editor.value !== newValue) {
        this.editor.value = newValue
      }
    },
    disabled (newValue) {
      this.editor.setReadOnly(newValue)
    },
    readonly (newValue) {
      this.editor.setReadOnly(newValue)
    }
  },
  mounted () {
    if (this.plugins.length) {
      this.plugins.forEach((plugin) => {
        Jodit.plugins.add(plugin.name, plugin.callback)
      })
    }
    const buttons = [
      'undo',
      'redo',
      '|',
      'fontsize',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'brush',
      '|',
      'align',
      'indent',
      'outdent',
      'ul',
      'ol',
      'eraser',
      '|',
      'paragraph',
      'image',
      'localImage',
      'table',
      '|',
      'link',
      'hr',
      'copyformat',
      'source',
      'fullsize',
      'preview',
    ]
    this.editor = new Jodit(this.$el, {
      language: 'zh_cn',
      buttons: buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      cleanHTML: {
        fillEmptyParagraph: false
      },
      minHeight: 400,
      localImageConfig: {
        url: process.env.VUE_APP_BASE_API + '/common/upload',
        Authorization: 'Bearer ' + getToken(),
        patt: '/' + './' + '/i'
      },
      uploader: {
        url: process.env.VUE_APP_BASE_API + '/common/upload',
        headers:{
          Authorization: 'Bearer ' + getToken(),
        },
        filesVariableName () {
          return 'files'
        },
        isSuccess: function (resp) {
          return resp.code === 200
        },
        getMsg: function (resp) {
          return resp.msg
        },
        process: function (resp) {
          return resp
        },
        error: function (e) {
          this.events.fire('errorMessage', e.getMessage(), 'error', 4000)
        },
        defaultHandlerSuccess: function (data) {
          const j = this.j || this
          const urlArr = data.url.split(',')
          urlArr.forEach(url => {
            j.s.insertImage(url)
          })
        },
        defaultHandlerError: function (resp) {
          this.events.fire('errorMessage', resp.msg)
        }
      },
      // videoUploader: {
      //   url: process.env.VUE_APP_BASE_API + '/common/upload',
      //   Authorization: 'Bearer ' + getToken(),
      //   filesVariableName () {
      //     return 'files'
      //   },
      //   isSuccess: function (resp) {
      //     return resp.code === 200
      //   },
      //   getMsg: function (resp) {
      //     return resp.msg
      //   },
      //   process: function (resp) {
      //     return resp
      //   },
      //   error: function (e) {
      //     this.events.fire('errorMessage', e.getMessage(), 'error', 4000)
      //   },
      //   defaultHandlerSuccess: function (data) {
      //     const j = this.j || this
      //     const htmlCode = '<video height="270" src="' + data.url + '" controls autoplay muted >' + '</<video>'
      //     j.s.restore()
      //     j.s.insertHTML(htmlCode)
      //   },
      //   defaultHandlerError: function (resp) {
      //     this.events.fire('errorMessage', resp.msg)
      //   }
      // },
      ...this.editorConfig
    })
    this.editor.value = this.value
    this.editor.events.on('change', (newValue) =>
      this.$emit('input', newValue)
    )
    this.editor.events.on('keyup', (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation()
      }
    })
    if (this.disabled) {
      this.editor.setReadOnly(this.disabled)
    }
    if (this.readonly) {
      this.editor.setReadOnly(this.readonly)
    }
  },
  beforeDestroy () {
    this.editor.destruct()
  }
}
</script>

<style>
@import './jodit/jodit.min.css';

.jodit_fullsize {
  background-color: white;
}

.jodit, .jodit *, .jodit-container, .jodit-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.15;
}

.jodit_sticky .jodit-toolbar__box {
  position: fixed;
  z-index: 3;
  top: 0;
  left: auto;
  border-bottom: 1px solid var(--color-border);
}

</style>
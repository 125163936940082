<template>
  <div class="app-container home">
    <h1>九成大厨客服管理系统</h1>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>


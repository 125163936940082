<template>
  <div id="app">
    <router-view />
    <theme-picker />
  </div>
</template>

<script>
import ThemePicker from "@/components/ThemePicker";
import {mixSocket} from "./utils/mixSocket";
export default {
  name: "App",
  mixins: [mixSocket],
  components: { ThemePicker },
  metaInfo() {
      return {
          title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
          titleTemplate: title => {
              return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
          }
      }
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    this.$store.getters.socketInstance.disconnect()
  },
  methods: {
    //初始化socket
    init() {
      const hasToken = this.$store.getters.token
      hasToken && this._initSocket()
    }
  }
};
</script>
<style scoped>
#app .theme-picker {
  display: none;
}
</style>

import {Notification} from 'element-ui'
//聊天窗口路由
const chatPath = '/kf/chat'
//提示语
const tipsPools = {
  online: '客服有用户上线啦！',
  newMsg: '客服有新消息啦！'
}

export const mixSocket = {
  data() {
    return {}
  },
  computed: {
    _token() {
      return this.$store.getters.token
    },
    _socketInstance() {
      return this.$store.getters.socketInstance
    },
    _visitedViews() {
      return this.$store.getters.visitedViews
    },
    _onChatView() {
      return this.$route.path === chatPath
    }
  },
  methods: {
    //初始化socket
    _initSocket() {
      this.$store.commit('kf/INIT_SOCKET', this._token)
      this._watchLogin()
      this._watchNew()
      this._watchMsg()
    },
    //监听是否在其他地方登录
    _watchLogin() {
      this._socketInstance.on('beforeConnect', _ => {
        console.log('===mix互踢===')
        this._disconnectSocket()
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push('/login')
        })
      })
    },
    //监听是否有用户上线
    _watchNew() {
      this._socketInstance.on('new', _ => {
        console.log('===mix用户上线===')
        this._go2ChatView(chatPath, tipsPools.online)
      })
    },
    //监听是否有消息推送
    _watchMsg() {
      this._socketInstance.on('message', _ => {
        console.log('===mix消息推送===')
        this._go2ChatView(chatPath, tipsPools.newMsg)
      })
    },
    //聊天页面是否存在
    _hasChatView(path) {
      const result = this._visitedViews.some(p => p.path === path)
      return result
    },
    //跳转聊天窗口
    _go2ChatView(path, msg) {
      const flag = this._hasChatView(path)
      flag && !this._onChatView && Notification.warning({message: msg, duration: 2000})
      !flag && this.$router.push(path)
    },
    //断开socket
    _disconnectSocket() {
      this._socketInstance.disconnect()
    }
  }
}
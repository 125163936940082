import io from "socket.io-client";

const state = {
  socketInstance: null
}

const mutations = {
  INIT_SOCKET: (state, token) => {
    state.socketInstance = io(`${process.env.VUE_APP_SOCKET_API}?token=${token}` || "/", {
      transports: ['websocket'],
      upgrade: false
    });
    //初始化
    state.socketInstance.on('connect', () => {
      console.log("[IM]连接初始化成功");
    }).on('disconnect', () => {
      console.log("[IM]连接已断开");
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
}

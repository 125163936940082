/* eslint-env node */
import Vue from 'vue'
import JoditEditor from './JoditEditor.vue'

export function install (Vue) {
  if (install.installed) {
    return
  }
  install.installed = true
  Vue.component('JoditEditor', JoditEditor)
  // Backwards compatible global component registration
  Vue.component('JoditVue', JoditEditor)
}

const plugin = { install }

Vue.use(plugin)

export default plugin
export { JoditEditor, JoditEditor as JoditVue }
export { Jodit } from './jodit/jodit.min.js'
